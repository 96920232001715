<template>
  <div class="channel">
    <h4>渠道预览<router-link :to="{path:'channel/add'}" class="float-right btn btn-primary btn-sm">添加渠道</router-link></h4>

    <div class="mt-3">
      <table class="table table-striped table-hover striped">
        <thead class="thead-light">
        <tr>
          <th>ID</th>
          <th>渠道</th>
          <th>渠道码</th>
          <th>联系人</th>
          <th>积分比例</th>
          <th>消耗（元）</th>
          <th>余额（元）</th>
          <th>添加时间</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in list" v-bind:key="row.index">
          <td v-for="item in row" :key="item.index">{{ item }}</td>
          <td>
            <router-link :to="'/channel/' + row.id + '/edit'" class="card-link">修改</router-link>
            <router-link :to="'/channel/' + row.id + '/recharge'" class="card-link">充值</router-link>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
  </div>
</template>
<script>
import Channel from "@/model/Channel"
export default {
  name: 'List',
  components: {
  },
  created() {
    this.init();
  },
  data(){
    return {
      list: [],
    }
  },
  methods: {
    init() {
      Channel.findAll().then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
          console.log(this.list)
        }
      });
    }

  }

}
</script>